var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseInput",
    _vm._b(
      {
        attrs: {
          type: _vm.showPassword ? "text" : "password",
          label: _vm.label,
          placeholder: _vm.label,
          value: _vm.value,
          error: _vm.error
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", $event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "right-side",
            fn: function(ref) {
              var error = ref.error
              return [
                !error
                  ? _c(
                      "button",
                      {
                        staticClass: " toggle-password",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.showPassword = !_vm.showPassword
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "reveal-icon h5 mr-2",
                          class: !_vm.showPassword
                            ? "tmlps-icn-hide-password"
                            : "tmlps-icn-see-password reveal-icon--active"
                        })
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      },
      "BaseInput",
      _vm.$attrs,
      false
    ),
    [
      _c("template", { slot: "left-side" }, [
        _c("i", { staticClass: "tmlps-icn-password left-icon" })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }