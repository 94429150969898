<template>
  <BaseInput
    :type="showPassword ? 'text' : 'password'"
    :label="label"
    :placeholder="label"
    :value="value"
    :error="error"
    v-bind="$attrs"
    @input="$emit('input', $event)"
  >
    <template slot="left-side">
      <i class="tmlps-icn-password left-icon"></i>
    </template>

    <template slot="right-side" slot-scope="{ error }">
      <button
        v-if="!error"
        type="button"
        class=" toggle-password"
        @click="showPassword = !showPassword"
      >
        <i
          class="reveal-icon h5 mr-2"
          :class="
            !showPassword ? 'tmlps-icn-hide-password' : 'tmlps-icn-see-password reveal-icon--active'
          "
        ></i>
      </button>
    </template>
  </BaseInput>
</template>

<script>
import BaseInput from '@/components/BaseInput';

export default {
  name: 'PasswordInput',
  components: {
    BaseInput,
  },
  props: {
    value: { type: [String, Number] },
    error: { type: String, default: null },
    label: { type: String, default: 'Contraseña' },
  },
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.left-icon
  margin-left: 0.75rem

.reveal-icon
  height: 1rem
  width: 1rem
  color: #C1C1C1
  &--active
    color: var(--primary)

.toggle-password
  background: none
  border: none
  &:focus
    outline: none
</style>
