<template>
  <img class="logo" :src="imageUrl" :alt="description" :height="height" :width="width" />
</template>

<script>
export default {
  name: 'Logo',
  props: {
    imageUrl: { type: String, required: true },
    description: { type: String, default: '' },
    height: { type: Number, default: 60 },
    width: { type: Number, default: 60 },
  },
};
</script>

<style scoped>
.logo {
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  object-fit: cover;
  object-position: center;
}
</style>
