var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    staticClass: "logo",
    attrs: {
      src: _vm.imageUrl,
      alt: _vm.description,
      height: _vm.height,
      width: _vm.width
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }